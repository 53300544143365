.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tobi-tile {
  cursor: pointer;
  text-align: center;
}

.tobi-active {
  background-color: #075272 !important;
  color: #fff;
}

.select-card {
  border: 'solid 1px #075272';
  border-radius: '10px';
  cursor: 'pointer';
}

.selected-card {
  border: 'solid 1px #075272';
  border-radius: '10px';
  cursor: 'pointer';
  background-color: #075272;
  color: #fff !important;
}

.select-card:hover {
  background-color: #075272;
  color: #fff;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  margin: 'auto';
  background-color: #eee;
}

.large-input {
  font-size: 14px;
  padding: 5px;
}